/** @jsx jsx */
import { Box, Container, Heading, jsx } from 'theme-ui'

import Layout from '~/layout/Layout'
import Link from '~/components/Link'
import Seo from '~/components/seo'
import { LinkType } from '~/types'

const DISPLAY_NAME = '404Page'

const RECOMMENDED_LINKS: LinkType[] = [
  {
    id: 'home',
    to: '/',
    text: 'Home',
  },
  {
    id: 'sign-in',
    href: `${process.env.GATSBY_APP_URL}/login`,
    text: 'Sign In',
  },
  {
    id: 'pricing',
    to: '/pricing',
    text: 'Pricing',
  },
  {
    id: 'faq',
    to: '/faq',
    text: 'FAQ',
  },
]

const Component = () => (
  <Layout>
    <Seo title="Not found" />
    <Container>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [null, null, null, '1fr 1.5fr'],
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <div>
          <Heading
            variant="heading1"
            mb={2}
            as="h1"
          >
            Sorry, we can&apos;t find that page.
          </Heading>
          <Box
            mb={5}
            variant="text.body"
          >
            Sorry, we couldn&apos;t find the page you&apos;re looking for.
            Please try another URL, or go to a suggested page below:
          </Box>
          <Heading
            variant="heading2"
            mb={2}
            as="h2"
          >
            Suggested Pages
          </Heading>
          <ul sx={{ columns: 2 }}>
            {RECOMMENDED_LINKS.map(({ href, id, text, to }) => (
              <li key={id}>
                <Link
                  to={to}
                  href={href}
                  sx={{
                    display: 'inline-block',
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  }}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  </Layout>
)

Component.displayName = DISPLAY_NAME

export default Component
